import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"
import get from "lodash.get"

import Layout from "../components/layout/layout"
import CtaFooter from "../components/cta-footer/footer"
import SEO from "../components/seo"
import { PlainSelector, PlainSelectorBlock } from "../blocks/PlainSelector"
import { PhoneCarousel, PhoneCarouselBlock } from "../blocks/PhoneCarousel"

const AppStore = require("../images/download-maxrewards-app-store.svg")
const PlayStore = require("../images/download-maxrewards-play-store.svg")

const IndexPage = ({ data }) => {
  const [indexData]: any = useLocalJsonForm(data?.dataJson, IndexFormOptions)
  const works = indexData?.works ? indexData?.works : []
  const worksTitle = indexData?.worksTitle || ""
  const blocks = indexData?.blocks ? indexData.blocks : []

  return (
    <Layout>
      <SEO title="Digital Wallet That Tracks Spend, Rewards, Deals, Credit Scores and More" />
      <section
        className="uk-section uk-section-primary"
        data-uk-scrollspy="target: h1, h2, p, .height-xsmall, .phone-wrapper, .uk-card; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container">
          <div className="uk-position-relative uk-flex uk-flex-middle uk-flex-center">
            <div className="hero-outer-circle uk-visible@s"></div>
            <div className="hero-inner-circle uk-visible@s"></div>
          </div>

          <h1 style={{ fontSize: "2.5rem" }}>
            The smarter
            <br className="uk-hidden@s" /> digital wallet.
          </h1>

          <div
            className="uk-position-relative uk-flex-center uk-grid-small"
            data-uk-grid
          >
            <div>
              <a href="https://apps.apple.com/us/app/maxrewards-earn-more-rewards/id1435710443">
                <AppStore className="height-xsmall" />
              </a>
            </div>

            <div>
              <a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <PlayStore className="height-xsmall" />
              </a>
            </div>
          </div>
        </div>

        <div className="phone-wrapper">
          <div className="uk-container uk-flex uk-flex-center uk-flex-middle">
            <img
              className="phone"
              src="/hero-image.png"
              alt="iPhone"
              style={{
                userSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}
            />
          </div>
        </div>

        <div className="stripe"></div>
        <div className="uk-container uk-position-relative">
          <div
            className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            <div className="uk-width-1-1">
              <h2 className="uk-text-center">
                A must-have for anyone with multiple credit cards
              </h2>
            </div>
            {works.map((work, index) => (
              <div key={work?.key ?? index.toString()}>
                <div className="uk-card uk-card-default uk-card-body">
                  <div className="uk-margin-auto uk-width-3-4">
                    <img src={work?.img || ""} />
                  </div>

                  <h3 className={`title ${work?.key}`}>{work?.title}</h3>

                  <h5>{work?.text}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="uk-section uk-section-muted uk-padding-remove-top"
        data-uk-scrollspy="target: p, img; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container uk-text-center">
          <p>MaxRewards supports cards from top issuers including:</p>

          <div className="uk-flex-center uk-flex-bottom issuers" data-uk-grid>
            <div>
              <img src="americanexpress.svg" alt="American Express" />
            </div>
            <div>
              <img src="bank-of-america.svg" alt="Bank of America" />
            </div>
            <div>
              <img src="barclays.svg" alt="Barclays" />
            </div>
            <div>
              <img className="large" src="capital-one.svg" alt="Capital One" />
            </div>
            <div>
              <img src="chase.svg" alt="Chase" />
            </div>
            <div>
              <img className="medium" src="citi.svg" alt="Citi" />
            </div>
            <div>
              <img src="discover.svg" alt="Discover" />
            </div>
            <div>
              <img src="us-bank.svg" alt="US Bank" />
            </div>
            <div>
              <img className="large" src="wells-fargo.svg" alt="Wells Fargo" />
            </div>
          </div>
        </div>
      </section>

      {blocks &&
        blocks.length > 0 &&
        blocks.map(({ _template, ...data }, index) => {
          switch (_template) {
            case "PlainSelectorBlock":
              return (
                <PlainSelector
                  key={index.toString()}
                  title={data?.title}
                  img={data?.img}
                  img_bg={data?.img_bg}
                  textData={data?.data}
                />
              )
            case "PhoneCarouselBlock":
              return (
                // @ts-ignore
                <PhoneCarousel key={index.toString()} {...data} />
              )
            default:
              return true
          }
        })}
      <CtaFooter />
    </Layout>
  )
}

const IndexFormOptions = {
  label: "Index",
  fields: [
    {
      label: "Title",
      name: "rawJson.worksTitle",
      component: "text",
    },
    {
      label: "How MaxRewards Works",
      name: "rawJson.works",
      description: "items for 'How MaxRewards Works' section",
      component: "group-list",
      fields: [
        {
          name: "title",
          label: "Title",
          component: "text",
        },
        {
          name: "text",
          label: "Description",
          component: "textarea",
        },
        {
          name: "img",
          label: "Image",
          component: "image",
          parse: (filename) => filename,
          uploadDir: () => "/static/",
          previewSrc: (formValues, { input }) => {
            const path = input.name.replace("rawFrontmatter", "frontmatter")
            const gastbyImageNode = get(formValues, path)
            if (!gastbyImageNode) return ""
            //specific to gatsby-image
            return gastbyImageNode
          },
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.title }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        title: "New",
        text: " ",
        image: "bills.jpeg",
      },
    },
    {
      label: "Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: {
        PlainSelectorBlock,
        PhoneCarouselBlock,
      },
    },
  ],
}

export const query = graphql`
  query homeQuery {
    dataJson(fileRelativePath: { eq: "/src/data/home.json" }) {
      works {
        key
        img
        title
        text
      }
      worksTitle
      blocks {
        _template
        title
        img
        img_bg
        data {
          title
          text
          img
        }
        reverse
        use_test
        testimonial {
          title
          text
          bg_decoration
        }
        use_dark
        shapes
        other_section_classes {
          remove_padding_top
          remove_padding_bot
          extra_padding_bot
        }
        id
        use_different_header
      }

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default IndexPage
